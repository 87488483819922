import { AuiSupernav } from "@avalara/skylab-react";

export default function NavBar() {
    return (
        <>
        <AuiSupernav 
            navItems={[
                {
                "label": "Home",
                "dataRef": "aui-supernav.home",
                "url": "https://home.avalara.com"
                }
            ]} 
            active="aui-supernav.home"
        />
        <main>
            <s-container>
                <s-row>
                    <s-col span="12" class="margin-top-lg">
                        <h1 className="margin-bottom-none margin-top-none">Template Management</h1>
                        {/* <p class="margin-bottom-none margin-top-none">Page description (optional)</p> */}
                    </s-col>
                </s-row>
            </s-container>
        </main>
    </>
    );
}