async function handleResponse(response) {
	if (response.ok) return await response.json();
	if (response.status === 400) {
		const error = await response.text();
		throw new Error(error);
	}
}

async function handleError(error) {
	console.log("API Call Failed. " + error);
}

function buildAIUri(sessionid, isHigherEnv = false) {
	return isHigherEnv ?
		`${process.env.REACT_APP_API_AI_HIGHER_ENV_BASE_URI}/authorize?response_type=${process.env.REACT_APP_AI_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_AI_HIGHER_ENV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AI_REDIRECT_URI}&scope=${process.env.REACT_APP_AI_SCOPES}&state=${sessionid}` :
		`${process.env.REACT_APP_API_AI_LOWER_ENV_BASE_URI}/authorize?response_type=${process.env.REACT_APP_AI_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_AI_LOWER_ENV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AI_REDIRECT_URI}&scope=${process.env.REACT_APP_AI_SCOPES}&state=${sessionid}`;
}

function buildAILogoutUri(idtoken, isHigherEnv = false) {
	return isHigherEnv ?
		`${process.env.REACT_APP_API_AI_HIGHER_ENV_BASE_URI}/endsession?id_token_hint=${idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AI_LOGOUT_REDIRECT_URI}` :
		`${process.env.REACT_APP_API_AI_LOWER_ENV_BASE_URI}/endsession?id_token_hint=${idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AI_LOGOUT_REDIRECT_URI}`;
}

function getTokenUri(isHigherEnv = false) {
	return isHigherEnv ? process.env.REACT_APP_API_AI_HIGHER_ENV_BASE_URI : process.env.REACT_APP_API_AI_LOWER_ENV_BASE_URI;
}

function getRestV2Uri(isHigherEnv = false) {
	return isHigherEnv ? process.env.REACT_APP_API_RESTV2_FQA_URI : process.env.REACT_APP_API_RESTV2_CQA_URI;
}

function getMigrationUri() {
	return process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_API_CSA_DEV_URI : process.env.REACT_APP_API_CSA_PRD_URI;
}

const chars = {
	"%": "%25",
	" ": "%20",
	"$": "%24",
	"\"": "%27",
	"(": "%28",
	")": "%29",
	"=": "%3D"
};

function encodeURL(url) {
	return url.replace(/[%" )(=$]/g, m => chars[m]);
}

export { handleResponse, handleError, buildAIUri, buildAILogoutUri, getRestV2Uri, getMigrationUri, getTokenUri, encodeURL }
