import {configureStore} from "@reduxjs/toolkit";

import sessionReducer from "./sessionSlice";
import oktaReducer from "./oktaSlice";

export default configureStore({
    reducer: {
        okta: oktaReducer,
        session: sessionReducer
    }
});