import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {

};

export const OktaSlice = createSlice({
  name: "okta",
  initialState: INITIAL_STATE,
  reducers: {

    addOktaAuthDetails: (state, action) => {
      return {
        ...state,
        idToken: action.payload.idToken,
        expiry: action.payload.expiry, 
        scopes: [...action.payload.scopes],
        email: action.payload.email,
        issuer: action.payload.issuer,
        name: action.payload.name
      }
    },
    resetOktaDetails: () => {
      return INITIAL_STATE;
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  addOktaAuthDetails,
  resetOktaDetails
} = OktaSlice.actions

export const selectIdToken = (state) => state.okta.idToken;
export const selectName = (state) => state.okta.name;

export default OktaSlice.reducer