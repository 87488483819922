import { AuiHeader, AuiFooter, SBox } from '@avalara/skylab-react';
import { useSelector } from 'react-redux';
import { selectName } from '../redux/oktaSlice.js';
import { SIcon } from '@avalara/skylab-react';
import NavBar from './NavBar.jsx';
import TemplatesContainer from './TemplatesContainer.jsx';

export default function Home() {
    const userName = useSelector(selectName);

    return (
        <>
        <AuiHeader
            productName={"Notifications"} 
            className="hide-account-menu"
            loginUrl={process.env.REACT_APP_OKTA_REDIRECT_URI}
            logoutUrl={process.env.REACT_APP_OKTA_REDIRECT_URI}
        >
            <div 
                className="flex right" 
                style={{
                    marginRight: 4 + 'em',
                    marginTop: 0.8 + 'em'
                }}
            >
                <SIcon style={{marginTop: 0.2 + 'em'}} name="user-filled" />
                <div style={{marginLeft: 0.8 + 'em'}}>{userName}</div>
            </div>
        </AuiHeader>
        <NavBar />
        <TemplatesContainer/>
        <AuiFooter className="pin-b"/>
        </>
    );
}