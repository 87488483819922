import {handleResponse, handleError} from "../utils";
async function getTemplates(token, startIndex, rowsPerPage) {
    try {
        const headers = {
            method: "GET",
            headers: {
                'accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            cache: "no-store"
        };
     
        const response = await fetch(`${process.env.REACT_APP_API_NOTIF_DEV_URI}?skip=${startIndex}&top=${rowsPerPage}`, headers);
        return handleResponse(response);
    }
    catch (error) {
        return handleError(error);
    }
}

async function addTemplate(token, inputData) {
    try {
        const headers = {
            method: "POST",
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputData)
        };

        const response = await fetch(`${process.env.REACT_APP_API_NOTIF_DEV_URI}`, headers);
        return handleResponse(response);
    }
    catch (error) {
        handleError(error);
    }
}

async function updateTemplate(token, inputData) {
    try {
        const headers = {
            method: "PUT",
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputData)
        };

        const id = inputData.id;

        const response = await fetch(`${process.env.REACT_APP_API_NOTIF_DEV_URI}/${id}`, headers);
        return handleResponse(response);
    }
    catch (error) {
        handleError(error);
    }
}

async function deleteTemplate(token, id) {
    try {
        const headers = {
            method: "DELETE",
            headers: {
                'accept': '/',
                'Authorization': `Bearer ${token}`
            }
        };

        const response = await fetch(`${process.env.REACT_APP_API_NOTIF_DEV_URI}/${id}`, headers);
        return response;
        // console.log(handleResponse(response));
        // return handleResponse(response);
    }
    catch (error) {
        handleError(error);
    }
}

export {getTemplates, addTemplate, updateTemplate, deleteTemplate}