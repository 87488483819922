import { OktaAuth } from "@okta/okta-auth-js";

const oktaConfig = {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    nonce: null,
  };

const oktaAuth = new OktaAuth(oktaConfig);

export { oktaConfig, oktaAuth };
