import { useSelector } from "react-redux";
import oktaSlice, { idToken } from "../redux/oktaSlice";
import { handleResponse, handleError, getTokenUri } from "../utils";
var token = "";
// new function
async function fetchAiToken(oktaToken) {
    try {
        const headers = {
            method: "POST",
            mode: "cors",
            headers: {
                'accept': '/',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"Token": oktaToken})
        };

        const response = await fetch(`https://21g41qo5lk.execute-api.us-west-2.amazonaws.com/default/dev-notification-token-generator-lambda`, headers);
        return handleResponse(response);
    }
    catch (error) {
        return handleError(error);
    }
}

async function fetchToken(ciToken) {
    try {
        var details = {
            "grant_type": "client_credentials",
            "scope": "notification:send notification.template:write notification.template:get notification.template:delete notification.feedback:get notification.eventconfig:write notification.eventconfig:get notification.event:publish"
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const request = {
            method: "POST",
            headers: {
                "accept": "application'json",
                "content-type": "application/x-www-form-urlencoded",
                "Authorization": `Basic ${ciToken}`
            },
            body: formBody
        };
        const response = await fetch(`https://identity.ci.avalara.io/connect/token`, request);
        // return handleResponse(response);
        return token;
    }
    catch (error) {
        return handleError(error);
    }
}

async function fetchTokenByAuthCode(authcode, isHigherEnv = false) {
    try {
        var details = {
            "code": authcode,
            "grant_type": process.env.REACT_APP_API_AI_AUTH_GRANT_TYPE,
            "client_id": isHigherEnv ? process.env.REACT_APP_AI_HIGHER_ENV_CLIENT_ID : process.env.REACT_APP_AI_LOWER_ENV_CLIENT_ID,
            "redirect_uri": process.env.REACT_APP_AI_REDIRECT_URI
        };
        
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const request = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formBody
        };
        
        const response = await fetch(`${getTokenUri(isHigherEnv)}/token`, request);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

async function fetchTokenByRefreshToken(refreshToken, isHigherEnv = false) {
    try {
        var details = {
            "refresh_token": refreshToken,
            "grant_type": process.env.REACT_APP_API_AI_REFRESH_GRANT_TYPE,
            "client_id": isHigherEnv ? process.env.REACT_APP_AI_HIGHER_ENV_CLIENT_ID : process.env.REACT_APP_AI_LOWER_ENV_CLIENT_ID,
        };
        
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const request = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formBody
        };
        
        const response = await fetch(`${getTokenUri(isHigherEnv)}/token`, request);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

async function fetchUserInfoByToken(token, isHigherEnv = false) {
    try {
        const request = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };
        
        const response = await fetch(`${getTokenUri(isHigherEnv)}/userinfo`, request);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export { fetchAiToken, fetchTokenByAuthCode, fetchTokenByRefreshToken, fetchUserInfoByToken }