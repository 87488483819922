import { SDialog, SRow, SCol, SSelect } from "@avalara/skylab-react";
import { useState } from "react";
import { updateTemplate } from "../services/templates-service";
import { handleError } from "../utils";

// var token = process.env.REACT_APP_TOKEN;

export default function UpdatePopUp({token, openStatus, item, refreshTemplates, handleClose}) {
    // console.log(item);
    const [inputData, setInputData] = useState(item);

    const handleChange = (inputIdentifier, newValue) => {
        setInputData((prevInputData) => {
            return {
                ...prevInputData,

                // override the value
                [inputIdentifier]: newValue
            }
        });
    }

    const handleContentChange = (inputIdentifier, newValue) => {
        setInputData((prevInputData) => {
            return {
                ...prevInputData,
                "content": {
                    ...prevInputData.content,

                    // override the value
                    [inputIdentifier]: newValue
                }
            }
        });
    }

    const handleUpdateClick = async () => {
        try
        {
            const res = await updateTemplate(token, inputData)
            console.log(res);
            await refreshTemplates();
            console.log("template updated");
            handleClose(false);
        }
        catch (error)
        {
            handleError(error);
        }
    }

    return (
        openStatus &&
        <SDialog
            open={openStatus}
            id="view-dialog"
            onS-dismiss={() => handleClose(false)}
            style={{minWidth: 45 + 'vw'}}
        >
            <div slot="header" id="dialog-title">Update Template</div>
            <div slot="body">
                <SRow>
                    <SCol span="4">
                        <label
                            htmlFor="template-name"
                        >
                            Template Name
                        </label>
                        <input
                            id="template-name"
                            type="text"
                            value={inputData.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                    </SCol>
                    <SCol span="auto">
                        <label
                            htmlFor="template-desc"
                        >
                            Template Description
                        </label>
                        <input
                            id="template-desc"
                            type="text"
                            value={inputData.desc}
                            onChange={(e) => handleChange('desc', e.target.value)}
                        />
                    </SCol>
                </SRow>

                {/* <SRow>
                    <SCol>
                        <label
                            htmlFor="channel-type"
                        >
                            Channel Type
                        </label>
                        <SSelect 
                            inputId="channel-type"
                            multiple={false} 
                            showSelectionCount={false}
                            noSearch={true}
                            optionsList={
                                [
                                    {label: "Email", value: "Email", selected: true},
                                    {label: "In-app", value: "In-app"},
                                    {label: "Both", value: "Both"},
                                ]
                            } 
                        />
                    </SCol>
                </SRow> */}

                <SRow>
                    <SCol>
                        <label
                            htmlFor="subject"
                        >
                                Subject
                        </label>
                        <input
                            id="subject"
                            type="text"
                            value={inputData.content.subject}
                            onChange={(e) => handleContentChange('subject', e.target.value)}
                        />
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <label
                            htmlFor="content"
                        >
                            Content
                        </label>
                        <textarea
                            id="content"
                            type="text"
                            value={inputData.content.html}
                            style={{overflowY: "auto", height: 30 + 'em'}}
                            onChange={(e) => handleContentChange('html', e.target.value)}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <div>
                    <button
                        className="primary"
                        onClick={handleUpdateClick}
                    >
                        Update
                    </button>
                    <button
                        className="secondary"
                        onClick={() => handleClose(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SDialog>
    );
}