import { SDialog, SBox, SContainer, SRow, SCol } from "@avalara/skylab-react";
export default function ViewPopUp({openStatus, item, handleClose}) {

    return (
        openStatus &&
        <SDialog
            open={openStatus}
            id="view-dialog"
            onS-dismiss={() => handleClose(false)}
            style={{minWidth: 45 + 'vw'}}
        >
            <div slot="header" id="dialog-title">Viewing Template</div>
            <div slot="body">
                <SRow>
                    <SCol span="4">
                        <label htmlFor="template-name">Template Name</label>
                        <SBox id="template-name">
                            <div>
                                {item.name}
                            </div>
                        </SBox>
                    </SCol>
                    <SCol span="auto">
                        <label htmlFor="template-desc">Template Description</label>
                        <SBox id="template-desc">
                            <div>
                                {item.desc}
                            </div>
                        </SBox>
                    </SCol>
                </SRow>

                {/* <SRow>
                    <SCol>
                        <label htmlFor="channel-type">Channel Type</label>
                        <SBox id="channel-type">
                            <div>
                                Both/Email/In-app (Hardcoded Value)
                            </div>
                        </SBox>
                    </SCol>
                </SRow> */}

                <SRow>
                    <SCol>
                        <label htmlFor="subject">Subject</label>
                        <SBox id="subject">
                            <div>
                                {item.content.subject}
                            </div>
                        </SBox>
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <label htmlFor="content">Content</label>
                        <SBox id="content">
                            <SContainer>
                                <div style={{overflowY: "auto", height: 30 + 'em'} }>
                                    {item.content.html}
                                </div>
                            </SContainer>
                        </SBox>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    );
}