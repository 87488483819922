import { SDialog, SRow, SCol, SBox } from "@avalara/skylab-react";
import { deleteTemplate } from "../services/templates-service";
import { handleError } from "../utils";

// var token = process.env.REACT_APP_TOKEN;

export default function DeletePopUp({token, openStatus, item, refreshTemplates, handleClose}) {
    const handleDeleteClick = async() => {
        try
        {
            const id = item.id;
            const res = await deleteTemplate(token, id)
            console.log(res);

            refreshTemplates();
            handleClose(false);
        }
        catch (error)
        {
            handleError(error);
        }
    }
    return (
        openStatus &&
        <SDialog
            open={openStatus}
            id="view-dialog"
            onS-dismiss={() => handleClose(false)}
            style={{minWidth: 45 + 'vw'}}
        >
            <div slot="header" id="dialog-title">Delete Template</div>
            <div slot="body">
            <SRow>
                    <SCol span="4">
                        <label htmlFor="template-name">Template Name</label>
                        <SBox id="template-name">
                            <div>
                                {item.name}
                            </div>
                        </SBox>
                    </SCol>
                    <SCol span="auto">
                        <label htmlFor="template-desc">Template Description</label>
                        <SBox id="template-desc">
                            <div>
                                {item.desc}
                            </div>
                        </SBox>
                    </SCol>
                </SRow>
                <div>
                    <h3>Are you sure?</h3>
                </div>
            </div>
            <div slot="footer">
            <div>
                    <button
                        className="primary"
                        onClick={handleDeleteClick}
                    >
                        Delete
                    </button>
                    <button
                        className="secondary"
                        onClick={() => handleClose(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SDialog>
    );
}