import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIdToken } from "../redux/oktaSlice";
import {SContainer, SIcon, SPagination, STooltip} from "@avalara/skylab-react";
// import TemplatesService from "../../services/templates-service.js";
import { getTemplates } from "../services/templates-service.js";
import { fetchAiToken } from "../services/ai-service.js";
import { handleError } from "../utils/apiHelpers.js";
import ViewPopUp from "./ViewPopUp.jsx";
import AddPopUp from "./AddPopUp.jsx";
import UpdatePopUp from "./UpdatePopUp.jsx";
import DeletePopUp from "./DeletePopUp.jsx";

// var aiToken = process.env.REACT_APP_TOKEN;

export default function TemplatesContainer() {
    const [templates, setTemplates] = useState([]);
    const [paginationDetails, setPaginationDetails] = useState({ startIndex: 0, rowsPerPage: 10 });
    const [countOfRecords, setCountOfRecords] = useState(0);
    const [token, setToken] = useState("");
    const [tokenExpiryTime, setTokenExpiryTime] = useState(300);
    const intervalTime = (tokenExpiryTime - 20) * 1000;
    const [countOfTime, setCountOfTime] = useState(0);

    const oktaToken = useSelector(selectIdToken);
    // console.log(oktaToken);

    // useEffect(() => {
    //     fetchTemplates();
    // }, []);

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== "")
        {
            console.log(token);
            fetchTemplates();
        }
    }, [paginationDetails, token]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // logic here
            // const currentTime = new Date().toLocaleTimeString();
            // console.log(`Hello! Current time is: ${currentTime}`)

            getToken();

        }, intervalTime);

        return () => clearInterval(intervalId);
    }, []);

    const getToken = async() => {
        try
        {
            const res = await fetchAiToken(oktaToken);
            const JSONRes = await JSON.parse(res);
            // store the Ai Token in cache and then use it
            console.log(JSONRes);
            // console.log(res.access_token);
            setToken(JSONRes.access_token);
            setTokenExpiryTime(JSONRes.expires_in);
        }
        catch (error)
        {
            handleError(error);
        }
    }

    const fetchTemplates = async() => {
        try
        {
         
            const res = await getTemplates(token, paginationDetails.startIndex, paginationDetails.rowsPerPage);
            const value = await res.value;
            const count = await res.count;
            console.log(res);
            setTemplates(value);
            setCountOfRecords(count);
        }
        catch (error)
        {
            setTemplates([]);
            handleError(error);
        }
    }
    
    // View Pop-Up
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [currentViewItem, setCurrentViewItem] = useState();

    function handleViewClick(item) {
        setCurrentViewItem(item);
        setIsViewOpen(true);
    }

    // Pagination
    function handlePagination(e) {
        // console.log(e);
        setPaginationDetails(prev => {
            if (prev.startIndex !== e.startIndex) {
                return { ...prev, startIndex: e.startIndex }
            }
            else if (prev.rowsPerPage !== e.rowsPerPage) {
                return { ...prev, rowsPerPage: e.rowsPerPage }
            }
            return prev;
        });
        
        // console.log(e);
    }

    // Add Pop-Up
    const [isAddOpen, setIsAddOpen] = useState(false);

    function handleAddClick() {
        setIsAddOpen(true);
    }

    // Update Pop-Up
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [currentUpdateItem, setCurrentUpdateItem] = useState();

    function handleUpdateClick(item) {
        setCurrentUpdateItem(item);
        setIsUpdateOpen(true);
        // console.log(currentUpdateItem);
    }
    
    // Delete Pop-Up
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [currentDeleteItem, setCurrentDeleteItem] = useState();

    function handleDeleteClick(id) {
        setCurrentDeleteItem(id);
        setIsDeleteOpen(true);
    }

    let PopUp = null;
    if(isAddOpen) PopUp = <AddPopUp token={token} openStatus={isAddOpen} refreshTemplates={fetchTemplates} handleClose={setIsAddOpen} />;
    else if(isViewOpen) PopUp = <ViewPopUp token={token} openStatus={isViewOpen} item={currentViewItem} handleClose={setIsViewOpen} />;
    else if(isUpdateOpen) PopUp = <UpdatePopUp token={token} openStatus={isUpdateOpen} item={currentUpdateItem} refreshTemplates={fetchTemplates} handleClose={setIsUpdateOpen} />;
    else if(isDeleteOpen) PopUp = <DeletePopUp token={token} openStatus={isDeleteOpen} item={currentDeleteItem} refreshTemplates={fetchTemplates} handleClose={setIsDeleteOpen} />; 

    return (
        <div 
            className="inner-body flex flex-dir-col"
        >
            <SContainer 
                className="flex"
                style={{width: 80 + "vw", justifyContent: "flex-end"}}
            >
                <div
                    className="margin-all-md"
                    style={{width: 5 + 'em'}}
                >
                    <button
                        className="icon ghost"
                        id="tooltip-trigger"
                        aria-label="Add"
                        aria-describedby="add-tooltip"
                        onClick={handleAddClick}  
                    >
                        <SIcon name="plus-circle" />    
                    </button>
                    <STooltip
                        tooltipId="add-tooltip"
                        triggerId="tooltip-trigger"
                        placement="right"
                    >
                        <span slot="content">Add Template</span>
                    </STooltip>
                </div>
            </SContainer>
            <SContainer style={{width: 80 + 'vw'}}>
                <s-table-container>
                    <table
                        className="show-gridlines"
                    >
                        <thead>
                            <tr>
                                <th
                                    style={{width: 25 + 'em'}}
                                >
                                    Name
                                </th>
                                <th
                                    style={{width: 35 + 'em'}}
                                >
                                    Description
                                </th>
                                {/* <th>Channel Type</th> */}
                                <th
                                    style={{width: 10 + 'em'}}
                                >
                                    Operations
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            templates.map((item) => (
                                item != null &&
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.desc}</td>
                                    {/* <td>{item.channelType}</td> */}
                                    {/* <td></td> */}
                                    <td>
                                        <div style={{display: "flex"}}>
                                            <button
                                                className="icon ghost"
                                                onClick={() => handleViewClick(item)}    
                                            >
                                                <SIcon name="eye-on" />    
                                            </button>
                                            <button
                                                className="icon ghost"
                                                onClick={() => handleUpdateClick(item)}
                                            >
                                                <SIcon name="edit-pencil" />
                                            </button>
                                            <button 
                                                className="icon ghost"
                                                onClick={() => handleDeleteClick(item)}
                                            >
                                                <SIcon name="trash" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </s-table-container>
                <br />
            </SContainer>
            {PopUp}
            <SPagination 
                className="justify-content-center"
                totalRecords={countOfRecords}
                hideRowsPerPage={true}
                onS-paginate={(e) => handlePagination(e.detail)}
            /> 
        </div>
    );
}