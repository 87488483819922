import { SDialog, SRow, SCol, SSelect } from "@avalara/skylab-react";
import { useState } from "react";
import { addTemplate } from "../services/templates-service";
import { handleError } from "../utils";

// var token = process.env.REACT_APP_TOKEN;

export default function AddPopUp({token, openStatus, refreshTemplates, handleClose}) {
    const [inputData, setInputData] = useState({
        "content": {
            "subject": "",
            "html": ""
        },
        "name": "",
        "desc": "",
    });

    const handleChange = (inputIdentifier, newValue) => {
        setInputData((prevInputData) => {
            return {
                ...prevInputData,

                // override the value
                [inputIdentifier]: newValue
            }
        });
    }

    const handleContentChange = (inputIdentifier, newValue) => {
        setInputData((prevInputData) => {
            return {
                ...prevInputData,
                "content": {
                    ...prevInputData.content,

                    // override the value
                    [inputIdentifier]: newValue
                }
            }
        });
    }

    const handleAddClick = async() => {
        try
        {
            const res = await addTemplate(token, inputData);
            console.log(res, Date.now());
            // console.log("Line 56",Date.now());
            refreshTemplates();
            handleClose(false);

        }
        catch (error)
        {
            handleError(error);
        }

        // console.log("Line 66",Date.now());
    }

    return (
        openStatus &&
        <SDialog
            open={openStatus}
            id="view-dialog"
            onS-dismiss={() => handleClose(false)}
            style={{minWidth: 45 + 'vw'}}
        >
            <div slot="header" id="dialog-title">Add Template</div>
            <div slot="body">
                <SRow>
                    <SCol span="4">
                        <label
                            htmlFor="template-name"
                            className="required"
                        >
                            Template Name
                        </label>
                        <input
                            id="template-name"
                            type="text"
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                    </SCol>
                    <SCol span="auto">
                        <label
                            htmlFor="template-desc"
                            className="required"
                        >
                            Template Description
                        </label>
                        <input
                            id="template-desc"
                            type="text"
                            onChange={(e) => handleChange('desc', e.target.value)}
                        />
                    </SCol>
                </SRow>

                {/* <SRow>
                    <SCol>
                        <label
                            htmlFor="channel-type"
                            className="required"
                        >
                            Channel Type
                        </label>
                        <SSelect 
                            inputId="channel-type"
                            multiple={false} 
                            showSelectionCount={false}
                            noSearch={true}
                            optionsList={
                                [
                                    {label: "Email", value: "Email", selected: true},
                                    {label: "In-app", value: "In-app"},
                                    {label: "Both", value: "Both"},
                                ]
                            } 
                        />
                    </SCol>
                </SRow> */}

                <SRow>
                    <SCol>
                        <label
                            htmlFor="subject"
                            className="required"
                        >
                                Subject
                        </label>
                        <input
                            id="subject"
                            type="text"
                            onChange={(e) => handleContentChange('subject', e.target.value)}
                        />
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <label
                            htmlFor="content"
                            className="required"
                        >
                            Content
                        </label>
                        <textarea
                            id="content"
                            type="text"
                            style={{overflowY: "auto", height: 30 + 'em'}}
                            onChange={(e) => handleContentChange('html', e.target.value)}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <div>
                    <button
                        className="primary"
                        onClick={handleAddClick}
                    >
                        Add
                    </button>
                    <button
                        className="secondary"
                        onClick={() => handleClose(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SDialog>
    );
}