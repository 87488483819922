import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";

import { addOktaAuthDetails } from "../../redux/oktaSlice";

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const { authState, oktaAuth } = useOktaAuth();
    console.log(authState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!authState || !oktaAuth) {
            return;
        };
        if (authState && !authState.isAuthenticated) {
            oktaAuth.signInWithRedirect({ originalUri: "/" })
        }
    }, [authState, oktaAuth]);

    if (!authState || !oktaAuth) {
        return;
    }

    if (authState.isAuthenticated) {
        dispatch(addOktaAuthDetails({
            idToken: authState.idToken.idToken,
            expiry: authState.idToken.expiresAt,
            scopes: [...authState.idToken.scopes],
            email: authState.idToken.claims.email,
            issuer: authState.idToken.issuer,
            name: authState.idToken.claims.name
        }));
        return <Component {...rest} />;
    }
    else {
        return <>
            <div className="redirect-page">
                <h1>Login Required!</h1>
                <h4>Redirecting to Okta...</h4>
            </div>
        </>;
    }
};

export default ProtectedRoute;