import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  source: {},
  target: {}
};

export const SessionSlice = createSlice({
  name: "session",
  initialState: INITIAL_STATE,
  reducers: {
    setSourceSession: (state, action) => {
      return {
        ...state,
        source: {
          traceId: action.payload.traceId,
          idToken: action.payload.idToken,
          current: action.payload.current,
          next: action.payload.next,
          timestamp: action.payload.timestamp,
          expiry: action.payload.expiry
        }
      }
    },
    setTargetSession: (state, action) => {
      return {
        ...state,
        target: {
          traceId: action.payload.traceId,
          idToken: action.payload.idToken,
          current: action.payload.current,
          next: action.payload.next,
          timestamp: action.payload.timestamp,
          expiry: action.payload.expiry
        }
      }
    },
    setSourceUserInfo: (state, action) => {
      return {
        ...state,
        source: {
          ...state.source,
          userinfo: action.payload
        }
      }
    },
    setTargetUserInfo: (state, action) => {
      return {
        ...state,
        target: {
          ...state.target,
          userinfo: action.payload
        }
      }
    },
    resetSourceSession: (state, action) => {
      return {
        ...state,
        source: {}
      }
    },
    resetTargetSession: (state, action) => {
      return {
        ...state,
        target: {}
      }
    },
    resetSession: () => {
      return INITIAL_STATE;
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setSourceSession,
  setTargetSession,
  setSourceUserInfo,
  setTargetUserInfo,
  resetSourceSession,
  resetTargetSession,
  resetSession
} = SessionSlice.actions

export default SessionSlice.reducer