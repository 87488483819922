import { Route, Routes } from "react-router-dom";
import { LoginCallback, Security} from "@okta/okta-react";
import "./App.css";

import Home from "./components/Home.jsx";
import AuthSuccess from "./components/auth/AuthSuccess.jsx";
import Logout from "./components/auth/Logout.jsx";
import ProtectedRoute from "./components/okta/ProtectedRoute.jsx";
import { oktaAuth } from "./oktaConfig.js";


function App() {
  const handleLoginRedirectCustom = async (_oktaAuth, originalUri) => {
    const redirectUri = originalUri || "/";
    window.location.replace(redirectUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={handleLoginRedirectCustom}>
        <Routes>
          <Route 
            path="/"
            exact={true}
            element={<ProtectedRoute component={Home} />}
          />
          <Route
            path="/auth/success"
            element={<ProtectedRoute component={AuthSuccess} />}
          />
          <Route
            path="/auth/endsession"
            element={<ProtectedRoute component={Logout} />}
          />
          <Route
            path="/login/callback"
            element={<LoginCallback />}
          />
          <Route
            path="*"
            element={<ProtectedRoute component={Home} />}
          />
        </Routes>
    </Security>
  );
}

export default App;
